<template>

 
<div class="hero back_img">
  <div class="content">

    <img class="logo_premium" src="/logo/premium_white.svg">

    <div class="text">{{ $t('premium_landing_subtitle') }}</div>

    <a href="#buy"  onclick="setCookie('link_after_login', 'checkout_subscribe')" class="btn_get_trial">{{ $t('premium_landing_button') }}</a>

    


    <div class="table_price">

      <div class="head">
        <div class="td">{{ $t('premium_landing_table_col1') }}</div>
        <div class="td">{{ $t('premium_landing_table_col2') }}</div>
      </div>
      <div class="body">
        <div class="tr">
          <div class="th">{{ $t('premium_landing_table_row1') }}</div>
          <div class="td"><i class="icon-check circle green"></i></div>
          <div class="td"><i class="icon-check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{{ $t('premium_landing_table_row2') }}</div>
          <div class="td"><i class="icon-close circle red"></i></div>
          <div class="td"><i class="icon-check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{{ $t('premium_landing_table_row4') }}</div>
          <div class="td"><i class="icon-close circle red"></i></div>
          <div class="td"><i class="icon-check circle green"></i></div>
        </div>
        <div class="tr">
          <div class="th">{{ $t('premium_landing_table_row3') }}</div>
          <div class="td"><i class="icon-close circle red"></i></div>
          <div class="td"><i class="icon-check circle green"></i></div>
        </div>
      </div>

    </div>

    <!-- <div class="after_table">Restrictions apply. <a href="/">Learn more here.</a></div> -->

  </div>
</div>


<!-- ------- -->


<div class="bl_diaries">
  <div class="content">

    <div class="info">
      <h2>{{ $t('premium_landing_slide1_title') }}</h2>
      <div class="sub">{{ $t('premium_landing_slide1_text') }}</div>
    </div>
    <div class="drs">

      <img class="dr_1" src="/images/premium/premium_diaries.jpg">

    </div>


  </div>
</div>


<!-- ------- -->


<div class="bl_ques">
  <div class="content">

    <div class="drs">

      <img class="dr_1" src="/images/premium/grow_question.jpg">

    </div>

    <div class="info">

      <h2>{{ $t('premium_landing_slide2_title') }}</h2>
      <div class="sub">{{ $t('premium_landing_slide2_text') }}</div>

    </div>

  </div>
</div>

<!-- ------- -->


<div class="bl_add">
  <div class="content">

    <div class="info">
      <h2>{{ $t('premium_landing_slide3_title') }}</h2>
      <div class="sub">{{ $t('premium_landing_slide3_text') }}</div>
    </div>
    <div class="drs">

      <img class="dr_1" src="/images/premium/necessary_things.jpg">

    </div>


  </div>
</div>

<!-- ------- -->


<div class="bl_prem">
  <div class="content">

    <div class="l_left">

      <h2>{{ $t('premium_landing_slide4_title') }}</h2>
      <ul class="sub">
        <li>{{ $t('premium_landing_slide4_feat1') }}</li>
        <li>{{ $t('premium_landing_slide4_feat2') }}</li>
        <li>{{ $t('premium_landing_slide4_feat3') }}</li>
        <li>{{ $t('premium_landing_slide4_feat4') }}</li>
      </ul>

    </div>

    <div class="l_right">
      <a href="<?php echo BaseBilling::getLink();?>" onclick="setCookie('link_after_login', 'checkout_subscribe')" class="ui button yellow big btn_get_trial">{{ $t('premium_landing_button') }}</a>
    </div>

  </div>
</div>
  

</template>

<script setup>
 
 const {$api, $ga, $popup} = useNuxtApp()



 
onMounted(() => {
  // useTopbarHiddenMobile().value = true;
  useNavbarThemeRequired().value = 'dark';
})
onUnmounted(() => {
  // useTopbarHiddenMobile().value = false;
  useNavbarThemeRequired().value = null;
});
  

</script>

<style scoped>



.back_img {
  background-image: url(/images/premium/back.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: top;
}

.hero {
  background-color: #0d0d0d;
  position: relative;
  display: flex;
}

.hero .content {
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  align-items: center;
  min-height: 50px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  margin-bottom: 6rem;
}

.hero .content .logo_premium {
  max-width: 470px;
  margin-top: 7rem;
    width: 90%;
}

.hero .content .text {
  font-size: 1.7rem;
  line-height: normal;
  color: white;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: center;
}

.hero .content .sub {
  font-size: 1rem;
  color: #a7a7a7;
  margin-top: 1rem;
}

.hero .content .btn_get_trial{
  text-transform: uppercase;
    color: black !important;
    background-color: gold;
    padding: 10px 40px;
    border-radius: 5px;
    font-weight: bold;
}

/* table price */
.table_price {
    display: flex;
    flex-direction: column;
    background-color: #221e17;
    padding: 3rem;
    max-width: 860px;
    width: 100%;
    margin-top: 6rem;
    color: white;
    font-size: 1.3rem;
}
.table_price .head{
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px #ffffff57 solid;
    height: 41px;
}

.table_price .head .td{
    width: 190px;
    text-align: center;

}

.table_price .body{
    display: flex;
    flex-direction: column;
}
.table_price .body .tr{
    display: flex;
    margin-top: 1.3rem;
}
.table_price .body .tr .th{
    margin-right: auto;
}
.table_price .body .tr .td{
    width: 190px;
    font-size: 1.5rem;
    text-align: center;
}



.after_table{
  margin-top: 1rem;
  color: #a7a7a7;
  margin-bottom: 6rem;
}

.after_table a{
  color: #a7a7a7;
}



/* DOARIES */


.bl_diaries {
  background-color: #0d0d0d;
  position: relative;
  display: flex;
  border-top: 1px #ffffff1f solid;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.bl_diaries .content {
  display: flex;
  max-width: 890px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  align-items: flex-start;
  min-height: 50px;
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
}
.bl_diaries .content .info {
  width: 100%;
}

.bl_diaries .content h2 {
  
  font-size: 2.1rem !important;
    max-width: 360px;
    line-height: 2.6rem;
}

.bl_diaries .content .sub {
  color: white;
  max-width: 370px;

  margin-top: 1rem;
}

.bl_diaries .content .drs {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 500px;
  margin-right: -7rem;
}

.bl_diaries .content .drs .dr_1 {
  max-width: 630px;
}






/* QUESTIONS */


.bl_ques {
  background-color: #0d0d0d;
  position: relative;
  display: flex;
  border-top: 1px #ffffff1f solid;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.bl_ques .content {
  display: flex;
  max-width: 890px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  align-items: flex-start;
  min-height: 50px;
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
}
.bl_ques .content .info {
  width: 100%;
}

.bl_ques .content h2 {
  color: white !important;
  
  font-size: 2.1rem !important;
    max-width: 360px;
    line-height: 2.6rem;
}

.bl_ques .content .sub {
  color: white;
  max-width: 370px;
}

.bl_ques .content .drs {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -20px;
  margin-left: -5rem;
  margin-right: 5rem;
}

.bl_ques .content .drs .dr_1 {
  max-width: 100%;
}

.bl_ques .content .drs .dr_2 {
  max-width: 640px;
  margin-left: -70px;
}



/* add */


.bl_add {
  position: relative;
  display: flex;
  border-top: 1px #ffffff1f solid;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #0d0d0d;
}

.bl_add .content {
  display: flex;
  max-width: 890px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  align-items: flex-start;
  min-height: 50px;
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
}
.bl_add .content .info{width: 100%;}
.bl_add .content h2{color: white!;
    font-size: 2.1rem !important;
    max-width: 360px;
    line-height: 2.6rem;}
.bl_add .content .sub{color: white;max-width: 370px;}
.bl_add .content .drs{width: 100%;display: flex;align-items: flex-end;justify-content: flex-end;width: 500px;margin-right: -7rem;}
.bl_add .content .drs .dr_1{max-width: 550px;}





/* QUESTIONS */


.bl_prem {
  background-color: #0d0d0d;
  position: relative;
  display: flex;
  border-top: 1px #ffffff1f solid;
  border-bottom: 1px #ffffff1f solid;
  padding-top: 7rem;
  padding-bottom: 9rem;
}

.bl_prem .content {
  display: flex;
  max-width: 890px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  align-items: flex-start;
  min-height: 50px;
  position: relative;
  z-index: 1;
  align-items: center;
}

.bl_prem .content .btn_get_trial{
  text-transform: uppercase;
  color: black!important;
}

.bl_prem .content .l_left{
    max-width: 530px;
}
.bl_prem .content .l_right{width: 19rem;margin-left: auto;text-align: center;}
.bl_prem .content h2{color: white!important;
    font-size: 2.1rem !important;
    max-width: 360px;
    line-height: 2.6rem;}
.bl_prem .content .sub{color: white;/* max-width: 370px; *//* text-align: center; */}



.bl_help{padding-top: 9rem;padding-bottom: 9rem;}
.bl_help .content {max-width: 580px;margin: 0 auto;}

.bl_help .content .list{

}

.bl_help .content h2{color: white!important;font-size: 1.4rem!important;}
.bl_help .content .ui.inverted.segment{
  background-color: transparent!important;
  padding: 0;
}

.bl_help .ui.accordion .title{border-top: 1px #ffffff4a solid;padding: 2rem 0;}
.bl_help .ui.accordion .title:first-child{border-top: 0px;}

i.green{color: #00ff00;}
i.red{color: #ff0000;}

@container pc (max-width: 600px) { 
  .table_price .body .tr{
    flex-wrap: nowrap;
  }
  .table_price .body .tr .td{
    width: 80px;
  }
  .table_price .body .tr .th{
    margin-right: auto;
    width: calc(100% - 160px);
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .table_price{
    padding: 1rem;
  }
  .table_price .head {align-items: center;padding-bottom: 13px;opacity: 0.5;align-content: center;}

  .table_price .head .td{
    width: 80px;
    font-size: 1rem;
    line-height: 1rem;
  }
  .bl_diaries{
    border-top:0px;
  }
  .bl_diaries .content .drs .dr_1{
    /* display: none; */
    max-width: 100%;

    width: 100%;
        object-fit: contain;
  }
  .bl_diaries .content .drs{width: 100%;/* flex-wrap: wrap; */margin-top: 2rem;margin-right: 0;}
  .bl_diaries .content{
    flex-direction: column;
    text-align: center;
    
  }
  .bl_diaries .content h2{
    margin: 0 auto;
  }
  .bl_diaries .content .sub{

    margin: 0 auto;
    margin-top: 1rem;
  }
  .bl_ques .content{
    flex-direction: column-reverse;
    text-align: center;
  }
  .bl_ques .content h2{
        margin: 0 auto;
  }
  .bl_ques .content .sub{
        margin: 0 auto;
        margin-top: 1rem;
  }
  .bl_ques .content .drs{
    flex-wrap: wrap;
  }
  .bl_ques .content .drs {
    width: 100%;
    margin-top: 2rem;
    margin-right: 0;
    margin-left: 0;
  }
  .bl_ques .content .drs .dr_1{
    /* display: none; */
  }

  .bl_ques .content .drs .dr_2{
    max-width: 640px;
    margin-left: -37px;
  }
  .bl_add .content{
    flex-direction: column;
    text-align: center;
  }
  .bl_add .content h2{
    margin-top: 0rem;
    margin: 0 auto;
  }
  .bl_add .content .sub{

    margin: 0 auto;
    margin-top: 1rem;
  }
  .bl_add .content .drs{
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0rem;
    margin-left: auto;
    margin-right: auto;
  }
  .bl_add .content .drs .dr_1 {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 0px;
    margin-top: 3rem;
    /* margin-top: -14rem; */
  }
  .bl_prem .content{

    flex-direction: column;
  }
  .bl_prem .content h2{
    text-align: center;
  }
  .bl_prem .content .sub{text-align: center;padding: 0;}
  .bl_prem .content .sub li{
    display:block;
    text-transform: capitalize;
  }
  .bl_prem .content .l_right{
    width: 100%;
    margin-left: auto;
    text-align: center;
    margin-top: 1rem;
  }

  .bl_help .content h2{
    text-align:center;
    padding: 0 15px;
  }
  .bl_help .content .list{
    padding: 0 2rem;
  }
}

  
 
</style>